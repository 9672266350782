import { useLocation, useParams } from 'react-router-dom';
import successImage from '../src/media/success.png';
function SuccessPage(){
        const location = useLocation();
        const returnUrl = location.state ? location.state.url : "";
        const {checkoutId} = useParams();
        // console.log(returnUrl);
    return (
      
      <div class="error-page d-flex align-items-center justify-content-center">
      <div class="error-container text-center p-4">
          <h1 class="mb-0 bravo" >BRAVO !</h1>
          <h2 class="display-6 error-message mb-3">Paiement effectué</h2>
          <p class="lead error-message mb-5">Nous vous remercions pour votre confiance.</p>
          <div class="d-flex justify-content-center gap-3">
              <a href={returnUrl} class="btn btn-glass px-4 py-2 mx-2">Retour sur le site du marchand </a>
              <a href={`https://pay.distriforce.shop/api/generate-recu/${checkoutId}`} target='_blank' class="btn btn-glass px-4 py-2 bg-danger">Télécharger le reçu</a>
          </div>
      </div>
  </div>
//     <div class="container-fluid">
//     <div class="row justify-content-center">
//         <div class=" col-lg-6 col-md-8">
//             <div class="card p-3">
//                 <section id="main-content" class="container">
//                     <div class="row-wrapper-x">
//                        <section class="wpb_row  ">
//                           <div class="wpb_column vc_column_container vc_col-sm-12">
//                              <div class="vc_column-inner ">
//                                 <div class="wpb_wrapper">
//                                    <div  class="wpb_single_image wpb_content_element text-center">
//                                     <img src={successImage} style={{"width":"200px"}} alt="" />
//                                      <h2 class="text-success">Paiement effectué</h2>
//                                      <p>Paiement effectué avec succès nous vous remercions</p>
//                                    </div>
//                                 </div>
//                              </div>
//                           </div>
//                        </section>
//                     </div>
//                  </section>
//             </div>
//         </div>
//     </div>
// </div>
    )
}

export default SuccessPage;
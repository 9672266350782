import { useLocation, useParams } from 'react-router-dom';
import errorImage from '../src/media/error.png';
function WaitingPage(){
    const location = useLocation();
    const returnUrl = location.state ? location.state.url : "";
    const {checkoutId} = useParams();

    // console.log(checkoutId);

    return (

      <div class="error-page d-flex align-items-center justify-content-center">
      <div class="error-container text-center p-4">
          <h1 class="wait-code mb-0" >Temps écoulé !</h1>
          <h2 class="display-6 error-message mb-3">La transaction est en cours de traitement. Cette opération peut prendre 24H.</h2>
          <h2 class="display-6 error-message mb-3" style={{color:"black"}}>Reference : {checkoutId}</h2>

            <p class="lead error-message mb-5">Vous pouvez nous ecrire à l'adresse : <b>distripay@distriforce.report</b>.</p>
          
          <div class="d-flex justify-content-center gap-3">
              <a href={returnUrl} class="btn btn-glass px-4 py-2">Retour sur le site du marchand</a>
              {/* <a href="#" class="btn btn-glass px-4 py-2">Report Problem</a> */}
          </div>
      </div>
  </div>
      
   //      <div class="container-fluid">
   //      <div class="row justify-content-center">
   //          <div class=" col-lg-6 col-md-8">
   //              <div class="card p-3">
   //                  <section id="main-content" class="container">
   //                      <div class="row-wrapper-x">
   //                         <section class="wpb_row  ">
   //                            <div class="wpb_column vc_column_container vc_col-sm-12">
   //                               <div class="vc_column-inner ">
   //                                  <div class="wpb_wrapper">
   //                                     <div  class="wpb_single_image wpb_content_element text-center">
   //                                      <img src={errorImage} style={{"width":"200px"}} alt="" />
   //                                       <h2 class="text-danger">Echec du paiement</h2>
   //                                       <p>Echec du paiement veuillez réessayer</p>
   //                                     </div>
   //                                  </div>
   //                               </div>
   //                            </div>
   //                         </section>
   //                      </div>
   //                   </section>
   //              </div>
   //          </div>
   //      </div>
   //  </div>
    )
}

export default WaitingPage;